import React from "react";
import AbouteSection from "../components/AbouteSection";
import Breadcormb from "../components/Breadcormb";
import ProductSection from "../components/ProductSection";
import Faq from "../components/Faq";
import ContectBanner from "../components/ContectBanner";

const AboutUs = ({ data }) => {
  return (
    <>
      <Breadcormb title={data[1].name} pageName={data[1].name} />
      <AbouteSection
        id={data[1].id}
        img={data[1].img}
        title={data[1].title}
        description={data[1].description}
        name={data[1].name}
      />
      <ProductSection data={data[2]} />
      <Faq />
      <ContectBanner />
    </>
  );
};

export default AboutUs;
