import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ id, icon, title, subtitle }) => {
  return (
    <>
      <div
        className="rts-service__single col-md-4"
        style={{ padding: "40px 32px" }}
      >
        <div className="rts-service__single--icon shared__hosting">
          <img src={icon} alt="" />
        </div>
        <Link to={`${id}`} className="rts-service__single--title">
          {title}
        </Link>
        <p className="rts-service__single--excerpt">{subtitle}</p>
        <Link to={`${id}`} className="rts-service__single--btn">
          View Details <i className="fa-regular fa-arrow-right"></i>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
