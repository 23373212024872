import React from "react";
import { Link } from "react-router-dom";

const Breadcormb = ({ title, slogan, pageName }) => {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530 contact__banner">
                <span className="starting__price">{pageName}</span>
                <h1 className="banner-title">{title}</h1>
                <p className="slogan">{slogan}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcormb;
