import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ data }) => {
  return (
    <>
      <footer className="rts-footer site-footer-one section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5 col-sm-6 rts-footer__widget--column">
              <div className="rts-footer__widget footer__widget">
                <Link to="#" className="footer__logo">
                  <img src="assets/images/logo/logo.png" alt="" />
                </Link>
                <p className="brand-desc">{data[1].description}</p>
                <div className="separator site-default-border"></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 rts-footer__widget--column">
              <div className="rts-footer__widget footer__widget extra-padding">
                <h5 className="widget-title">Quick links</h5>
                <div className="rts-footer__widget--menu ">
                  <ul>
                    {data?.map((items, index) => (
                      <li key={index}>
                        <Link to={items.url}>{items.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6 rts-footer__widget--column">
              <div className="rts-footer__widget footer__widget extra-padding">
                <h5 className="widget-title">Product links</h5>
                <div className="rts-footer__widget--menu ">
                  <ul>
                    {data[2].submenu?.map((items, index, id) => (
                      <li key={index}>
                        <Link to={`${items.id}`}>{items.name}</Link>
                      </li>
                    ))}

                    <li>
                      <Link to="refund"> Refund Cancellation </Link>
                    </li>
                    <li>
                      <Link to="terms"> Terms Conditions </Link>
                    </li>
                    <li>
                      <Link to="privacy"> Privacy Policy  </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="rts-footer__copyright text-center">
              <p>© Copyright 2024.quickbillhub.com.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
