import React from "react";
import Breadcormb from "../components/Breadcormb";
import { useParams } from "react-router";
import AbouteSection from "../components/AbouteSection";
import ContectBanner from "../components/ContectBanner";

const Products = ({ data }) => {
  const { id } = useParams();
  return (
    <>
      {data?.map((items) => (
        <>
          {items.submenu
            ?.filter((item) => item.id == id)
            .map((item, index) => (
              <Breadcormb
                key={index}
                title={item.name}
                slogan={item.slogan}
                pageName={item.pageName}
              />
            ))}
        </>
      ))}

      {data?.map((item, index) => (
        <>
          {" "}
          {item.submenu
            ?.filter((item) => item.id == id)
            .map((item, index) => (
              <>
                {item.page?.map((item, index) => (
                  <AbouteSection
                    key={index}
                    id={item.id}
                    title={item.title}
                    img={item.image}
                    description={item.description}
                  />
                ))}
              </>
            ))}
        </>
      ))}
      <ContectBanner />
    </>
  );
};

export default Products;
