import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router";

const Layout = ({data}) => {
  return (
    <>
      <Header data={data} />
      <Outlet />
      <Footer data={data}/>
    </>
  );
};

export default Layout;
