import React from "react";
import Heading from "./Heading";
import ProductCard from "./ProductCard";

const ProductSection = ({ data }) => {
  return (
    <>
      <section className="rts-service section__padding">
        <div className="container">
          <div className="row justify-content-center">
            <Heading title=" Products"  subtitle=" Quick Bill Hub offer various products which is being offered by traditional payment gateways but directly with Bank"/>
          </div>
          <div class="row justify-content-center">
            {data.submenu?.map((item, index) => (
              <ProductCard
                key={index}
                id={item.id}
                icon={item.icon}
                title={item.name}
                subtitle={item.descriptionSort}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductSection;
