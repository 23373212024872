import React from "react";
import Breadcormb from "../components/Breadcormb";

const PrivacyPolicy = () => {
    return (
        <>
            <Breadcormb title="Privacy Policy" pageName="Privacy Policy" />
            <div class="container py-3">
                <h2 class="display-3 colo-def"></h2>
                <p>
                    <b>Last updated: July 21, 2023</b>
                </p>
                <p>
                    This legal agreement is an electronic record in terms of the Indian
                    Information Technology Act, 2000 and rules there under as applicable
                    and the amended provisions about electronic records in various
                    statutes as amended by the Indian Information Technology Act, 2000.
                    This electronic record is generated by a computer system and does not
                    require any physical or digital signatures.
                </p>
                <p>
                    We use Your Personal data to provide and improve the Service. By using
                    the Service, You agree to the collection and use of information in
                    accordance with this Privacy Policy. This legal document is published
                    in accordance with the provisions of Rule 3 (1) of the Indian
                    Information Technology (Intermediaries guidelines) Rules, 2011 and
                    Rule 4 of the Information Technology (Reasonable security practices
                    and procedures and sensitive personal data or information) Rules, 2011
                    of Information Technology Act, 2000 amended through In-formation
                    Technology Amendment Act, 2008 that require publishing the Terms of
                    Use and practices for access and usage of any functional website.
                    <p>
                        This Website is created and operated by M/s NKPR FINTECH SOLUTIONS PVT LTD. Finance and Investment Private Limited,
                        (hereinafter referred to as “We”, “Our”, and “Us”) and operating
                        under the brand name ‘quickbillhub’ (“Brand name”). The creator of this
                        Privacy Policy ensures a steady commitment to Your privacy with
                        regard to the protection of your invaluable information that you may
                        share across on this Website. This privacy policy contains
                        information about the Website “www.quickbillhub.com (“Website”).
                    </p>
                    <p>
                        For the purpose of these Privacy Policy (“Privacy Policy”), wherever
                        the context so requires, “We”, “Our”, and “Us” shall mean and refer
                        to the Website. “You”, “Your”, “Yourself”, “User” shall mean and
                        refer to natural and legal individuals and firms who shall be users
                        of this website provided by us and who is competent to enter into
                        binding contracts, as per Indian laws. “Third Parties” refer to any
                        website, platform or individual apart from the Users and the creator
                        of this Website.
                    </p>
                    <p>
                        To provide You with Our uninterrupted use of the Website, We may
                        collect and, in some circumstances, disclose information about you
                        with your permission. To ensure better protection of Your privacy,
                        We provide this notice explaining Our information collection and
                        disclosure policies, and the choices You make about the way Your
                        information is collected and used.
                    </p>
                    <p>
                        This Privacy Policy shall be in compliance with the General Data
                        Protection Regulation (GDPR) in effect from April 15, 2023, and any
                        and all provisions that may read to the contrary shall be deemed to
                        be void and unenforceable as of that date. If you do not agree with
                        the terms and conditions of our Privacy Policy, including in
                        relation to the manner of collection or use of your information,
                        please do not use or access the Site. If you have any questions or
                        concerns regarding this Privacy Policy, you should contact our
                        Customer Support Desk at 8149696437
                    </p>
                </p>
                <h3>Interpretation and Definitions</h3>
                <h4>Interpretation</h4>
                <p>
                    The words of which the initial letter is capitalized have meanings
                    defined under the following conditions.
                </p>
                <p>
                    The following definitions shall have the same meaning regardless of
                    whether they appear in singular or in plural.
                </p>
                <h3>Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li class="w-100">
                        <p>
                            <strong>You</strong> means the individual accessing or using the
                            Service, or the company, or other legal entity on behalf of which
                            such individual is accessing or using the Service, as applicable.
                        </p>
                    </li>
                    <li class="w-100 pl-0">
                        <p>
                            <strong>Company</strong> (referred to as either "the Company",
                            "We", "Us" or "Our" in this Agreement) refers to NKPR FINTECH SOLUTIONS PVT LTD.
                        </p>
                    </li>
                    <li class="w-100">
                        <strong>Affiliate</strong> means an entity that controls, is
                        controlled by or is under common control with a party, where
                        "control" means ownership of 50% or more of the shares, equity
                        interest or other securities entitled to vote for election of
                        directors or other managing authority.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>Account</strong> means a unique account created for You to
                        access our Service or parts of our Service.
                    </li>
                    <li class="w-100">
                        <strong>Website</strong> refers to NKPR FINTECH SOLUTIONS PVT LTD. , accessible from
                        https://quickbillhub.com
                    </li>{" "}
                    <li class="w-100 pl-0">
                        <strong>Service</strong> refers to the Website.
                    </li>
                    <li class="w-100">
                        <strong>Country</strong> refers to: Maharashtra, India
                    </li>
                    <li class="w-100 pl-0">
                        <p>
                            <strong>Service Provider</strong> means any natural or legal
                            person who processes the data on behalf of the Company. It refers
                            to third-party companies or individuals employed by the Company to
                            facilitate the Service, to provide the Service on behalf of the
                            Company, to perform services related to the Service or to assist
                            the Company in analyzing how the Service is used.
                        </p>
                    </li>
                    <li class="w-100">
                        <strong>Third-party Social Media Service</strong> refers to any
                        website or any social network website through which a User can log
                        in or create an account to use the Service.
                    </li>
                    <li class="w-100 pl-0">
                        <p>
                            <strong>Personal Data</strong> is any information that relates to
                            an identified or identifiable individual.
                        </p>
                    </li>
                    <li class="w-100">
                        <strong>Cookies</strong> are small files that are placed on Your
                        computer, mobile device or any other device by a website, containing
                        the details of Your browsing history on that website among its many
                        uses.
                    </li>{" "}
                    <li class="w-100 pl-0">
                        <strong>Usage Data</strong> refers to data collected automatically,
                        either generated by the use of the Service or from the Service
                        infrastructure itself (for example, the duration of a page visit).
                    </li>
                </ul>
                <h3 class="mt-3">Collecting and Using Your Personal Data</h3>
                <h4>Types of Data Collected</h4>
                <h5>Personal Data</h5>
                <p>
                    While using Our Service, We may ask You to provide Us with certain
                    personally identifiable information that can be used to contact or
                    identify You. Personally identifiable information may include, but is
                    not limited to:
                </p>
                <ul>
                    <li class="w-100">Email address</li>
                    <li class="w-100 pl-0">First name and last name</li>{" "}
                    <li class="w-100 pl-0">Phone number</li>{" "}
                    <li class="w-100 pl-0">
                        Address, State, Province, ZIP/Postal code, City
                    </li>{" "}
                    <li class="w-100 ">Usage Data</li>
                </ul>
                <h4>Usage Data</h4>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>
                    Usage Data may include information such as Your Device's Internet
                    Protocol address (e.g. IP address), browser type, browser version, the
                    pages of our Service that You visit, the time and date of Your visit,
                    the time spent on those pages, unique device identifiers and other
                    diagnostic data.
                </p>
                <p>
                    When You access the Service by or through a mobile device, We may
                    collect certain information automatically, including, but not limited
                    to, the type of mobile device You use, Your mobile device unique ID,
                    the IP address of Your mobile device, Your mobile operating system,
                    the type of mobile Internet browser You use, unique device identifiers
                    and other diagnostic data.
                </p>
                <p>
                    We may also collect information that Your browser sends whenever You
                    visit our Service or when You access the Service by or through a
                    mobile device.
                </p>
                <h4>Tracking Technologies and Cookies</h4>
                <p>
                    We use Cookies and similar tracking technologies to track the activity
                    on Our Service and store certain information. Tracking technologies
                    used are beacons, tags, and scripts to collect and track information
                    and to improve and analyze Our Service.
                </p>
                <p>
                    You can instruct Your browser to refuse all Cookies or to indicate
                    when a Cookie is being sent. However, if You do not accept Cookies,
                    You may not be able to use some parts of our Service.
                </p>
                <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
                    remain on your personal computer or mobile device when You go offline,
                    while Session Cookies are deleted as soon as You close your web
                    browser. Learn more about cookies:{" "}
                    <a href="">
                        All About Cookies
                    </a>
                    .
                </p>
                <p>
                    We use both session and persistent Cookies for the purposes set out
                    below:
                </p>
                <ul>
                    <li class="w-100">
                        <p>
                            <strong>Necessary / Essential Cookies</strong>
                        </p>
                        <p>Type: Session Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies are essential to provide You with services
                            available through the Website and to enable You to use some of its
                            features. They help to authenticate users and prevent fraudulent
                            use of user accounts. Without these Cookies, the services that You
                            have asked for cannot be provided, and We only use these Cookies
                            to provide You with those services.
                        </p>
                    </li>
                    <li class="w-100 pl-0">
                        <p>
                            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                        </p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies identify if users have accepted the use of
                            cookies on the Website.
                        </p>
                    </li>
                    <li class="w-100">
                        <p>
                            <strong>Functionality Cookies</strong>
                        </p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies allow us to remember choices You make when
                            You use the Website, such as remembering your login details or
                            language preference. The purpose of these Cookies is to provide
                            You with a more personal experience and to avoid You having to
                            re-enter your preferences every time You use the Website.
                        </p>
                    </li>
                </ul>
                <p>
                    For more information about the cookies we use and your choices
                    regarding cookies, please visit our Cookies Policy.
                </p>
                <h2>Use of Your Personal Data</h2>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                    <li class="w-100">
                        <strong>To provide and maintain our Service</strong>, including to
                        monitor the usage of our Service.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>To manage Your Account:</strong> to manage Your registration
                        as a user of the Service. The Personal Data You provide can give You
                        access to different functionalities of the Service that are
                        available to You as a registered user.
                    </li>
                    <li class="w-100">
                        <strong>For the performance of a contract:</strong> the development,
                        compliance and undertaking of the purchase contract for the
                        products, items or services You have purchased or of any other
                        contract with Us through the Service.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>To contact You:</strong> To contact You by email, telephone
                        calls, SMS, or other equivalent forms of electronic communication,
                        such as a mobile application's push notifications regarding updates
                        or informative communications related to the functionalities,
                        products or contracted services, including the security updates,
                        when necessary or reasonable for their implementation.
                    </li>
                    <li class="w-100">
                        <strong>To provide You</strong> with news, special offers and
                        general information about other goods, services and events which we
                        offer that are similar to those that you have already purchased or
                        enquired about unless You have opted not to receive such
                        information.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>To manage Your requests:</strong> To attend and manage Your
                        requests to Us.
                    </li>
                </ul>
                <p>
                    We may share your personal information in the following situations:
                </p>
                <ul>
                    <li class="w-100">
                        <strong>With Service Providers:</strong> We may share Your personal
                        information with Service Providers to monitor and analyze the use of
                        our Service, to contact You.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>For Business transfers:</strong> We may share or transfer
                        Your personal information in connection with, or during negotiations
                        of, any merger, sale of Company assets, financing, or acquisition of
                        all or a portion of our business to another company.
                    </li>
                    <li class="w-100">
                        <strong>With Affiliates:</strong> We may share Your information with
                        Our affiliates, in which case we will require those affiliates to
                        honor this Privacy Policy. Affiliates include Our parent company and
                        any other subsidiaries, joint venture partners or other companies
                        that We control or that are under common control with Us.
                    </li>
                    <li class="w-100 pl-0">
                        <strong>With Business partners:</strong> We may share Your
                        information with Our business partners to offer You certain
                        products, services or promotions.
                    </li>
                    <li class="w-100">
                        <strong>With other users:</strong> when You share personal
                        information or otherwise interact in the public areas with other
                        users, such information may be viewed by all users and may be
                        publicly distributed outside. If You interact with other users or
                        register through a Third-Party Social Media Service, Your contacts
                        on the Third-Party Social Media Service may see Your name, profile,
                        pictures and description of Your activity. Similarly, other users
                        will be able to view descriptions of Your activity, communicate with
                        You and view Your profile.
                    </li>
                </ul>
                <h3>Retention of Your Personal Data</h3>
                <p>
                    The Company will retain Your Personal Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy. We will
                    retain and use Your Personal Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required to retain
                    your data to comply with applicable laws), resolve disputes, and
                    enforce our legal agreements and policies.
                </p>
                <p>
                    The Company will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter period of
                    time, except when this data is used to strengthen the security or to
                    improve the functionality of Our Service, or We are legally obligated
                    to retain this data for longer time periods.
                </p>
                <h2>Transfer of Your Personal Data</h2>
                <p>
                    Your information, including Personal Data, is processed at the
                    Company's operating offices and in any other places where the parties
                    involved in the processing are located. It means that this information
                    may be transferred to — and maintained on — computers located outside
                    of Your state, province, country or other governmental jurisdiction
                    where the data protection laws may differ than those from Your
                    jurisdiction.
                </p>
                <p>
                    Your consent to this Privacy Policy followed by Your submission of
                    such information represents Your agreement to that transfer.
                </p>
                <p>
                    The Company will take all steps reasonably necessary to ensure that
                    Your data is treated securely and in accordance with this Privacy
                    Policy and no transfer of Your Personal Data will take place to an
                    organization or a country unless there are adequate controls in place
                    including the security of Your data and other personal information.
                </p>
                <h3>Disclosure of Your Personal Data</h3>
                <h2>Business Transactions</h2>
                <p>
                    If the Company is involved in a merger, acquisition or asset sale,
                    Your Personal Data may be transferred. We will provide notice before
                    Your Personal Data is transferred and becomes subject to a different
                    Privacy Policy.
                </p>
                <h4>Law enforcement</h4>
                <p>
                    Under certain circumstances, the Company may be required to disclose
                    Your Personal Data if required to do so by law or in response to valid
                    requests by public authorities (e.g. a court or a government agency).
                </p>
                <h4>Other legal requirements</h4>
                <p>
                    The Company may disclose Your Personal Data in the good faith belief
                    that such action is necessary to:
                </p>
                <ul>
                    <li class="w-100">Comply with a legal obligation</li>
                    <li class="w-100 pl-0">
                        Protect and defend the rights or property of the Company
                    </li>
                    <li class="w-100">
                        Prevent or investigate possible wrongdoing in connection with the
                        Service
                    </li>
                    <li class="w-100 pl-0">
                        Protect the personal safety of Users of the Service or the public
                    </li>
                    <li class="w-100">Protect against legal liability</li>
                </ul>
                <h3>Security of Your Personal Data</h3>
                <p>
                    The security of Your Personal Data is important to Us, but remember
                    that no method of transmission over the Internet, or method of
                    electronic storage is 100% secure. While We strive to use commercially
                    acceptable means to protect Your Personal Data, We cannot guarantee
                    its absolute security.
                </p>
                <h3>Consent Withdrawal, Data Download &amp; Data Removal Requests</h3>To
                withdraw your consent, or to request the download or delete your data
                with us for any or all our services at any time, please email to
                info@quickbillhub.com<h2>Children's Privacy</h2>
                <p>
                    Our Service does not address anyone under the age of 13. We do not
                    knowingly collect personally identifiable information from anyone
                    under the age of 13. If You are a parent or guardian and You are aware
                    that Your child has provided Us with Personal Data, please contact Us.
                    If We become aware that We have collected Personal Data from anyone
                    under the age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                </p>
                <p>
                    If We need to rely on consent as a legal basis for processing Your
                    information and Your country requires consent from a parent, We may
                    require Your parent's consent before We collect and use that
                    information.
                </p>
                <h2>Links to Other Websites</h2>
                <p>
                    Our Service may contain links to other websites that are not operated
                    by Us. If You click on a third party link, You will be directed to
                    that third party's site. We strongly advise You to review the Privacy
                    Policy of every site You visit.
                </p>
                <p>
                    We have no control over and assume no responsibility for the content,
                    privacy policies or practices of any third party sites or services.
                </p>
                <h3>Changes to this Privacy Policy</h3>
                <p>
                    We may update our Privacy Policy from time to time. We will notify You
                    of any changes by posting the new Privacy Policy on this page.
                </p>
                <p>
                    We will let You know via email and/or a prominent notice on Our
                    Service, prior to the change becoming effective and update the "Last
                    updated" date at the top of this Privacy Policy.
                </p>
                <p>
                    You are advised to review this Privacy Policy periodically for any
                    changes. Changes to this Privacy Policy are effective when they are
                    posted on this page.
                </p>
                <h3>Termination of Sub Merchant Service Agreement</h3>
                <p>
                    At any point in time if Company discovers that Sub Merchants are
                    dealing / trading / offering services from the Banned Merchant as
                    detailed in Annexure “B” of Service Agreement then Company will take
                    appropriate action to comply as per the regulatory requirements on
                    Immediate basis.
                </p>
                <p>
                    The termination of this Agreement shall not affect the rights or
                    liabilities of either Party incurred prior to such termination. In
                    addition, any act performed during the term of this Agreement which
                    may result in a dispute post termination or any provision expressed to
                    survive this Agreement or to be effective on termination or the
                    obligations set out in this Clause shall remain in full force and
                    effect notwithstanding termination.
                </p>
                <p>
                    All materials, documentation, instruction manuals, guidelines, letters
                    and writings and any other confidential materials issued by Company
                    from time to time in respect of this Agreement, whether in respect of
                    the utilization of API or otherwise shall be returned by Sub Merchant
                    to Company upon termination.
                </p>
                <p>
                    In case of a “charge back”, repudiation of any transaction by a
                    Customer, or a request for refund by any Customer for any reason
                    whatsoever, Company shall be entitled to cancel Authorization and
                    refuse to make any payments to Sub Merchant. If there are insufficient
                    funds available in the Sub Merchants Account maintained with Company;
                    the Sub Merchants shall on receipt of the claim from Company
                    undertakes forthwith to pay to Company, the amount of the refund /
                    charge back / repudiation to the extent to which such funds proves
                    inadequate.
                </p>
                <p>
                    In case of Fraud Chargeback occurrence more than once and having
                    aggregate value of more than Rs 50,000, Company reserves the right to
                    forfeit the Security Deposit given by Sub Merchant without any prior
                    intimation.
                </p>
                <p>
                    In case the Sub Merchants uses quickbillhub UPI Services for any other
                    category / business other than mentioned during on boarding, then the
                    amount collected for the day / period will be blocked and will not be
                    released and the security deposit (if any) will also stand forfeited
                    without any prior intimation.
                </p>
                <p>
                    During the period of agreement, if Company or Bank finds out that the
                    UPI APIs are not being used for the purpose intended i.e., the UPI
                    APIs are not being used in the app for which registration was done,
                    then Master Merchant reserves the right to forfeit the security
                    deposit and any funds that has been collected will also be forfeited
                    without prior notice.
                </p>
                <p>
                    The security deposit will be refunded to Sub Merchants after 3 months
                    from the date of termination of this service agreement. In case there
                    is any chargeback during these 3 months, then the chargeback amount
                    will be deducted from the security deposit.
                </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;
