import React from "react";
import { Link } from "react-router-dom";

const ContectBanner = () => {
  return (
    <>
      <div className="rts-cta-two shared-page-bg pt--120">
        <div className="container">
          <div className="row">
            <div className="rts-cta-two__wrapper">
              <div className="cta__shape"></div>
              <div className="cta-content">
                <span
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Need help choosing a plan?
                </span>
                <h4
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-duration="800"
                  className="sal-animate"
                >
                  Need help? We're always here for you.
                </h4>
              </div>
              <div className="cta-btn">
                <Link
                  to="contact_us"
                  className="contact__us primary__btn btn__two secondary__bg secondary__color"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContectBanner;
