import React from "react";

const Faq = () => {
  return (
    <>
      <section className="rts-faq bg-white section__padding">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-5 col-md-10">
              <div className="rts-faq__first">
                <h3 className="title">You have Questions ?</h3>
                <p>
                  If your query isn’t listed below, you can directly reach out
                  to us on info@quickbillhub.com
                </p>
                <img src="assets/images/faq/faq__animated2.png" alt="faq" />
              </div>
            </div>
            <div className="col-lg-6 col-md-10 offset-lg-1">
              <div className="rts-faq__accordion">
                <div className="accordion accordion-flush" id="rts-accordion">
                  <div className="accordion-item active">
                    <div className="accordion-header" id="first">
                      <h4
                        className="accordion-button collapse show"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__one"
                        aria-controls="item__one"
                        style={{ display: " block" }}
                      >
                        Are there any hidden charges or fees?
                      </h4>
                    </div>
                    <div
                      id="item__one"
                      className="accordion-collapse collapse show"
                      aria-labelledby="first"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      We strive to be transparent with our pricing, and we do not have any hidden fees or charges. However, it’s always recommended to review the terms and conditions or pricing details of any product or service you are interested in to ensure you have a clear understanding of the costs involved.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="two">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__two"
                        aria-controls="item__two"
                      >
                        Can I request a custom pricing package?
                      </h4>
                    </div>
                    <div
                      id="item__two"
                      className="accordion-collapse collapse"
                      aria-labelledby="two"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      Yes, for certain products or services, we may offer custom pricing packages based on your specific requirements. Please contact our sales team or customer support to discuss your needs and explore possible custom pricing options.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="three">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__three"
                        aria-controls="item__three"
                      >
                        Are there any hidden charges or fees?
                      </h4>
                    </div>
                    <div
                      id="item__three"
                      className="accordion-collapse collapse"
                      aria-labelledby="three"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      We strive to be transparent with our pricing, and we do not have any hidden fees or charges. However, it’s always recommended to review the terms and conditions or pricing details of any product or service you are interested in to ensure you have a clear understanding of the costs involved.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="four">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__four"
                        aria-controls="item__four"
                      >
                       Do you offer discounts or promotions?
                      </h4>
                    </div>
                    <div
                      id="item__four"
                      className="accordion-collapse collapse"
                      aria-labelledby="four"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                      Yes, we periodically offer discounts and promotions. These may be seasonal, promotional, or based on specific events or customer loyalty. Please check our website or contact our customer support for current discounts or promotions that may apply to your purchase.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
