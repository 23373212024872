import React from "react";

const Heading = (props) => {
  return (
    <>
      <div className="rts-section text-center w-530">
        <h3
          className="rts-section__title sal-animate"
          data-sal="slide-down"
          data-sal-delay="300"
          data-sal-duration="800"
        >
         {props.title}
        </h3>
        <p
          className="rts-section__description sal-animate"
          data-sal="slide-down"
          data-sal-delay="400"
          data-sal-duration="800"
        >
        {props.subtitle}
        </p>
      </div>
    </>
  );
};

export default Heading;
