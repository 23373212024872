import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import RefundCancellation from "./pages/RefundCancellation";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const navData = [
    {
      id: 1,
      name: "Home",
      url: "/",
      hero: [
        {
          id: 1,
          img: "",
          title: "Where your financial dreams become reality",
          subtitle:
            "Welcome to  Quick Bill Hub your trusted partner for seamless bill payments!",
          button: [
            {
              id: "1",
              text: "Get Started",
              url: "/a",
            },
            {
              id: "2",
              text: "Plans Pricing",
              url: "/b",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "About Us",
      url: "/about_us",
      img: "assets/images/b1.png",
      title:
        "Welcome to  Quick Bill Hub your trusted partner for seamless bill payments!",
      description:
        " We understand that managing bills can be time-consuming and stressful. That’s why we’ve created a user-friendly platform to simplify the process.",
    },
    {
      id: 3,
      name: "Products",
      submenu: [
        {
          id: 1,
          name: "Bill Payment",
          descriptionSort: "Quick pay your bills using Quick Bill Hub.",
          pageName: "Products",
          slogan: "",
          icon: "assets/images/service/shared__hosting.svg",
          page: [
            {
              id: 1,
              image: "assets/images/bill.jpg",
              title: "What are online bill payments? ",
              description:
                "Online bill payments, also known as online bill pay or B2B payments, allow finance teams to quickly and easily pay suppliers from a digital bank account or spend management platform. This process eliminates the need for back-and-forth paperwork, streamlining payments and keeping finances organized. It’s especially beneficial during month-end reconciliations and year-end close processes.",
            },
            {
              id: 2,
              image: "assets/images/bill2.png",
              title: "Why are online bill payments important?",
              description:
                "Automation : Set up automatic payments for recurring to avoid late payment fees. Receive email notifications for due bills. Audit Trail: Ensure a visible audit trail for seamless audits. Vendor Management: Quickly update vendor information, manage payment details, tax documentation, and payments in real time.",
            },
          ],
        },
        {
          id: 2,
          name: "Pay Recharge Bills",
          descriptionSort:
            "Quick Pay your Recharge bills using Quick Bill Hub.",
          pageName: "Products",
          slogan: "",
          icon: "assets/images/service/shared__hosting.svg",
          page: [
            {
              id: 1,
              image: "assets/images/r2.png",
              title: "Paying Recharge bills has never been easier.",
              description:
                "Choose your biller, enter the amount, and hit “Pay.” Join Quick Bill Hub today and experience hassle-free bill payments like never before!",
            },
            // {
            //   id: 2,
            //   image: "assets/images/r1.png",
            //   title: "All-in-One Bill Payment platform for businesses",
            //   description:
            //     "Discover the ultimate all-in-one bill payment platform for businesses. Streamline transactions, enhance efficiency, and boost financial convenience.",
            // },
          ],
        },
      ],
    },
    // {
    //   id: 4,
    //   name: "Pricing",
    //   url: "/Pricing",
    // },
    {
      id: 4,
      name: "Contact Us",
      url: "/contact_us",
    },
  ];
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout data={navData} />}>
          <Route index element={<Home data={navData} />} />
          <Route path="/about_us" element={<AboutUs data={navData} />} />
          <Route path="/:id" element={<Products data={navData} />} />
          <Route path="/pricing" element={<Pricing data={navData} />} />
          <Route path="/contact_us" element={<ContactUs data={navData} />} />
          <Route path="/refund" element={<RefundCancellation />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
