import React from "react";

const AbouteSection = ({ id, img, title, description, name }) => {
  return (
    <>
      <section
        className={`rts-hosting-feature-two section__padding ${
          id % 2 === 0 ? "black__friday--support" : null
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div
              className={`col-lg-6 ${
                id % 2 === 0 ? "order-2 offset-lg-1" : null
              }`}
            >
              <div
                className="rts-hosting-feature-two__image sal-animate"
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="800"
              >
                <img className="w-75 mx-auto d-flex" src={img} alt="" />
              </div>
            </div>
            <div
              className={`col-lg-5  ${id % 2 === 0 ? null : "offset-lg-1"} `}
            >
              <div className="rts-hosting-feature-two__content">
                <div className="rts-section__two">
                  <h3 className="mb-5">
                    <b>{name}</b>
                  </h3>
                  <h2
                    className="title sal-animate"
                    data-sal="slide-down"
                    data-sal-delay="300"
                    data-sal-duration="800"
                  >
                    {title}
                  </h2>
                  <p
                    className="description sal-animate"
                    data-sal="slide-down"
                    data-sal-delay="400"
                    data-sal-duration="800"
                  >
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AbouteSection;
