import React from "react";
import Breadcormb from "../components/Breadcormb";

const ContactUs = ({ data }) => {
  return (
    <>
      <Breadcormb title={data[3].name} pageName={data[3].name} />

      <section className="rts-contact-form no-bg pt--120 pb--120">
        <div className="container">
          <div className="row g-30 pb--120 justify-content-sm-center">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-map-location-dot"></i>
                  </div>
                  <div className="content">
                    <h5 className="info mb-0">Office Address:</h5>
                    <p className="mb-0">1" Fir, Office no 1, Ganesh Smruti Blgd, Gaothan, Virar west Thane, Maharashtara 401303</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-envelope-open"></i>
                  </div>
                  <div className="content w-100">
                    <h5 className="info mb-0">
                      Email Address:
                    </h5>
                    <a href="mailto:info@quickbillhub.com.">
                        info@quickbillhub.com.
                      </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-phone"></i>
                  </div>
                  <div className="content w-220">
                    <h5 className="info mb-0">
                     Phone Number :
                    </h5>
                    <a href="tel: 9181496 96437">(+91)  81496 96437</a> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-30 justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              <div className="contact-form">
                <div
                  className="contact-form__content sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="100"
                  data-sal-duration="800"
                >
                  <div className="contact-form__content--image">
                    <img
                      src="assets/images/contact/contact-form.png"
                      width="260"
                      height="188"
                      alt=""
                    />
                  </div>
                  <h1 className="contact-form__content--title">
                    Ask a Question
                  </h1>
                  <p className="contact-form__content--description">
                    Schedule a call today and one of our experts to help you
                    decide which service is ideal for your business and budget.
                  </p>
                  <div className="contact__shape support-page">
                    <img
                      src="assets/images/contact/contact__animated__arrow.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1 col-md-10">
              <div className="form">
                <h5>Send a message</h5>
                <form className="form__content" method="post" action="/">
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="name"
                      id="name"
                      placeholder="what is your name?"
                      required=""
                    />
                    <input
                      type="email"
                      className="input-form"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required=""
                    />
                  </div>
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="Phone"
                      id="phone"
                      placeholder="Phone Number"
                      required=""
                    />
                  </div>

                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="A brief description about your consultation"
                    required=""
                  ></textarea>

                  <button type="submit" className="submit__btn mt-3">
                    Submit Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
