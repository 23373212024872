import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FiAlignRight } from "react-icons/fi";

const Header = ({ data }) => {
  return (
    <>
      <header className="rts-header style-one header__default">
        <div className="container">
          <div className="row">
            <div className="rts-header__wrapper">
              <div className="rts-header__logo">
                <Link to="/" className="site-logo">
                  <img
                    className="logo-white"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "265px" }}
                  />
                  <img
                    className="logo-dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                  />
                </Link>
              </div>

              <nav className="rts-header__menu" id="mobile-menu">
                <div className="hostie-menu">
                  <ul className="list-unstyled hostie-desktop-menu">
                    {data?.map(({ name, id, url, submenu, index }) => (
                      <li key={index} className="menu-item hostie-has-dropdown">
                        <NavLink
                          to={url}
                          className="hostie-dropdown-main-element"
                        >
                          {name}
                        </NavLink>
                        {submenu ? (
                          <ul className="hostie-submenu list-unstyled menu-pages">
                            {submenu?.map(({ name, id, url, index }) => (
                              <li className="nav-item" key={index}>
                                <Link className="nav-link" to={`${id}`}>
                                  {name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
              <div className="rts-header__right">
                <Link
                  href="https://hostie-whmcs.themewant.com/"
                  className="login__btn"
                >
                  Call Now
                </Link>
                <button
                  id="menu-btn"
                  class="mobile__active menu-btn p-0"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <FiAlignRight />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div
            class="offcanvas-header"
            style={{ borderBottom: "2px solid #181257" }}
          >
            <Link to="/" className="site-logo">
              <img
                className="logo-white"
                src="assets/images/logo/logo.png"
                alt="logo"
                style={{ width: "200px" }}
              />
            </Link>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <hr></hr>
          </div>
          <div class="offcanvas-body p-4">
            <div class="dropdown mt-4">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                {data?.map(({ name, id, url, submenu, index }) => (
                  <li key={index} class="nav-item dropdown">
                    <Link class="nav-link" to={url}>
                      {name}
                    </Link>
                    {submenu ? (
                      <>
                        <Link
                          class="nav-link dropdown-toggle"
                          to={url}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {name}
                        </Link>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {submenu?.map(({ name, id, url, index }) => (
                            <li key={index}>
                              <Link class="dropdown-item" to={`${id}`}>
                                {name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
