import React from "react";
import Breadcormb from "../components/Breadcormb";
import PricingCard from "../components/PricingCard";
import ContectBanner from "../components/ContectBanner";

const Pricing = ({ data }) => {
  return (
    <>
      {/* <Breadcormb title={data[3].name} pageName={data[3].name} /> */}

      {/* <div className="rts-pricing-plan bg-white section__padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="rts-section  text-center">
              <h2
                className="rts-section__title sal-animate"
                data-sal="slide-down"
                data-sal-delay="100"
                data-sal-duration="800"
              >
                Choose Hosting Plan
              </h2>
              <p
                className="rts-section__description w-420 sal-animate"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-duration="800"
              >
                Globally incubate next-generation e-services via state of the
                art technology.
              </p>
            </div>
          </div>
          <div className="row">
            <PricingCard />
          </div>
        </div>
      </div>
      <ContectBanner /> */}
    </>
  );
};

export default Pricing;
