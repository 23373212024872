import React from "react";
import { Link } from "react-router-dom";

const Hero = ({ data }) => {
  return (
    <>
      <section className="rts-hero rts-hero__one banner-style-home-one">
        <div className="container">
          <div className="rts-hero__blur-area"></div>
          <div className="row align-items-end position-relative">
            <div className="col-lg-6">
              <div className="rts-hero__content w-550">
                <h1
                  className="heading sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-duration="800"
                >
                  {data.hero[0].title}
                </h1>
                <p
                  className="description sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-duration="800"
                >
                  {data.hero[0].subtitle}
                </p>
                <div
                  className="rts-hero__content--group sal-animate"
                  data-sal="slide-down"
                  data-sal-delay="500"
                  data-sal-duration="800"
                >
                  <Link
                    to={data.hero[0].button[0].url}
                    className="primary__btn white__bg"
                  >
                    {data.hero[0].button[0].text}
                    <i className="fa-regular fa-long-arrow-right"></i>
                  </Link>
                  <Link
                    to={data.hero[0].button[1].url}
                    className="btn__zero plan__btn"
                  >
                    {data.hero[0].button[1].text}
                    <i className="fa-regular fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rts-hero__images position-relative">
                <div className="rts-hero-main">
                  <div className="image-main ">
                    <img
                      className="main top-bottom2"
                      src="assets/images/b1.png"
                      alt=""
                    />
                  </div>
                  {/* <img
                    className="hero-shape one"
                    src="assets/images/banner/hosting.svg"
                    alt=""
                  /> */}
                </div>
                <div className="rts-hero__images--shape">
                  <div className="one top-bottom">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="two bottom-top">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="three top-bottom">
                    <img src="assets/images/banner/top.svg" alt="" />
                  </div>
                  <div className="four bottom-top">
                    <img src="assets/images/banner/right.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
