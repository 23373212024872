import React from "react";
import Hero from "../components/Hero";
import ProductSection from "../components/ProductSection";
import ContectBanner from "../components/ContectBanner";
import Faq from "../components/Faq";

const Home = (props) => {
  return (
    <>
      <Hero data={props.data[0]} />
      <ProductSection data={props.data[2]} />
      <Faq />
      <ContectBanner />
    </>
  );
};

export default Home;
